@use '@angular/material' as mat;

@mixin iot-platform-ui-form-field-theme($theme) {
  .iot-platform-ui-form-field {
    .mat-mdc-text-field-wrapper {
      margin-bottom: 4px !important;
    }

    .mat-mdc-form-field-error-wrapper {
      top: -4px !important;
    }

    &--datepicker-toggle {
      height: 16px;

      button.mdc-icon-button {
        height: 16px !important;
        width: 16px !important;
      }

      .mat-icon {
        font-size: 16px;
        height: 18px;
        width: 18px;
      }
    }
  }
}
